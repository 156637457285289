@tailwind base;
@tailwind components;
@tailwind utilities;

.loading-spinner {
  border-top-color: #6dba62;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* width */
.scrollBar::-webkit-scrollbar {
  height: 7px;
}

.scrollBar::-webkit-scrollbar-thumb {
  background: #d4d4d8;
  border-radius: 5px;
}

.scrollBar::-webkit-scrollbar-thumb:hover {
  background: #a1a1aa;
}
/* Track */
.scrollBar::-webkit-scrollbar-track {
  background: #ffffff;
}

.marker-position {
  margin-bottom: 15px;
}
